<template>
  <div class="position-relative">
    <BaseTopLine> </BaseTopLine>
    <div
      class="py-10 px-15 d-flex gap-15 align-items-end justify-content-between border-top"
      style="background-color: #1d1e2c"
      ref="topFilter"
    >
      <div class="d-flex gap-5 align-items-center">
        <span class="text-white fsc-11">{{ dataLang['tracker_text2'] }}</span>
        <div class="d-flex gap-5 flex-grow-1">
          <BaseInputNew
            placeholder=""
            style="width: 130px"
            @input="fillFilter"
            inputClass="bg-transparent text-white"
            :additionalParams="{ type: 'like' }"
            inputName="gps__tracker_id"
            value=""
            nameSign=""
          />
          <div class="btn btn-sm btn-primary fsc-11 px-20" @click="loadData">
            {{ dataLang['reestr_fines_text9'] }}
          </div>
        </div>
      </div>
      <div class="d-flex gap-5 align-items-center">
        <span class="text-white fsc-11">{{ dataLang['tracker_text3'] }}</span>
        <div class="d-flex gap-5 flex-grow-1">
          <BaseInputNew
            placeholder=""
            style="width: 130px"
            @input="fillFilter"
            inputClass="bg-transparent text-white"
            :additionalParams="{ type: 'like' }"
            inputName="gps__imei"
            value=""
            nameSign=""
          />
          <div class="btn btn-sm btn-primary fsc-11 px-20" @click="loadData">
            {{ dataLang['reestr_fines_text9'] }}
          </div>
        </div>
      </div>
      <div class="d-flex gap-5 align-items-center">
        <span class="text-white fsc-11">{{ dataLang['tracker_text4'] }}</span>
        <div class="d-flex gap-5 flex-grow-1">
          <BaseInputNew
            placeholder=""
            style="width: 130px"
            @input="fillFilter"
            inputClass="bg-transparent text-white"
            :additionalParams="{ type: 'like' }"
            inputName="vehicles__gosnumber"
            value=""
            nameSign=""
          />
          <div class="btn btn-sm btn-primary fsc-11 px-20" @click="loadData">
            {{ dataLang['reestr_fines_text9'] }}
          </div>
        </div>
      </div>
      <div class="d-flex gap-5 align-items-center">
        <span class="text-white fsc-11">{{ dataLang['tracker_text17'] }}</span>
        <div class="d-flex gap-5 flex-grow-1">
          <BaseInputNew
            placeholder=""
            style="width: 130px"
            @input="fillFilter"
            inputClass="bg-transparent text-white"
            inputName="gps__company_id"
            value=""
            nameSign=""
          />
          <div class="btn btn-sm btn-primary fsc-11 px-20" @click="loadData">
            {{ dataLang['reestr_fines_text9'] }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="position-relative h-100 container-fluid"
      style="min-height: 250px"
    >
      <BaseLoader v-if="localLoading"></BaseLoader>
      <div
        class="d-flex justify-content-between align-items-center mt-15 mb-10"
      >
        <div class="d-flex gap-10">
          <div
            class="base-button base-button--action"
            @click="$modal.show('loadFileWithInfo')"
          >
            <img
              :src="require(`@/assets/icons/to-exel.svg`)"
              width="16"
              height="16"
            />
            {{ dataLang['tracker_text18'] }}
          </div>

          <div
            class="base-button base-button--action"
            @click="
              () =>
                activeSelectRowsMultiple.length
                  ? $modal.show('acceptDeleteModal')
                  : $modal.show('selectRow')
            "
          >
            <img
              :src="require(`@/assets/icons/adddel.svg`)"
              width="13.5"
              height="13.5"
            />
            {{ dataLang['tracker_text9'] }}
          </div>
          <div
            class="base-button base-button--action"
            @click="
              () =>
                activeSelectRowsMultiple.length
                  ? $modal.show('selectOwner')
                  : $modal.show('selectRow')
            "
          >
            {{ dataLang['tracker_text19'] }}
          </div>
          <div
            class="base-button base-button--action"
            @click="
              () =>
                activeSelectRowsMultiple.length
                  ? $modal.show('deattachOwnerModal')
                  : $modal.show('selectRow')
            "
          >
            {{ dataLang['tracker_text20'] }}
          </div>

          <a
            href="https://docs.google.com/spreadsheets/d/1vkZuc5tkFOzLz65n0fcsKZFy8zkNlbo9sCM5zfpq6f8/edit#gid=1624217125"
            target="_blank"
            class="btn btn-sm text-white fsc-11"
            style="background-color: #0056af"
          >
            {{ dataLang['tracker_text10'] }}
          </a>
        </div>

        <div class="d-flex gap-10">
          <div
            class="btn btn-sm text-white fsc-11"
            style="background-color: #0056af"
            @click="() => loadExel('/super/trackers/download/template')"
          >
            {{ dataLang['tracker_text21'] }}
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-between align-items-center mt-15 mb-10"
      >
        <div class="d-flex gap-15 align-items-center">
          <div
            class="btn btn-sm btn-thrity text-white fsc-14"
            @click="loadData(1)"
          >
            {{ dataLang['register_btn_filter_on'] }}
          </div>
          <div
            class="text-decoration-underline fsc-14 text-dark"
            style="cursor: pointer"
            @click="resetFilter"
          >
            {{ dataLang['register_btn_filter_off'] }}
          </div>
        </div>
        <BasePagination
          :page-count="numPages"
          :pagination-params="paginationFinesStateParams"
          :showLimitInput="false"
          :page-range="10"
          :margin-pages="0"
          :break-view-class="''"
          :click-handler="loadData"
          :prev-text="''"
          :next-text="''"
          :container-class="'custom-pagination'"
          :page-class="'custom-pagination__item'"
          :pageLinkClass="'custom-pagination__item-link'"
        >
        </BasePagination>
      </div>
      <div class="overflow-auto" style="min-height: 400px">
        <ve-table
          style="width: 100%"
          :columns="columns"
          :table-data="dataTable"
          :border-x="true"
          :border-y="true"
          row-key-field-name="gps__id"
          ref="mainTable"
          :eventCustomOption="eventCustomOption"
          v-if="!localLoading"
          :checkbox-option="{
            selectedRowChange: selectedRowChange,
            selectedAllChange: selectedAllChange,
            hideSelectAll: false
          }"
          :clickHighlight="false"
          :cell-style-option="cellStyleOption"
          :rowStyleOption="{
            hoverHighlight: true
          }"
          :cellSelectionOption="{ enable: false }"
        >
        </ve-table>
      </div>
    </div>

    <BaseModal
      name="selectOwner"
      width="420"
      :clickToClose="false"
      @beforeClose="() => (selectsFromModal.owners = [])"
      :shiftY="0.5"
      :adaptive="true"
      height="295"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('selectOwner')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p class="text-center fw-semi-bold mb-35">
            {{ dataLang['tracker_text52'] }}
          </p>
          <div class="fsc-14 mb-35">
            <BaseSelect
              class=""
              placeholder=""
              selectLabel=""
              deselectLabel=""
              v-model="selectsFromModal.owners"
              label="companies__name"
              :custom-label="
                    ({ companies__name, companies__id }) => {
                      return companies__id ? companies__name + ', ID:' + companies__id : companies__name
                    }
                  "
              :options="selectsDataForModal.owners"
              style="width: 100%"
              :maxHeight="100"
              openDirection="top"
            >
              <template slot="noOptions">{{
                dataLang['select_empty']
              }}</template>
              <template slot="noResult">{{
                dataLang['select_search_empty']
              }}</template>
              <template slot="selectedLabel">{{
                dataLang['select_selected']
              }}</template>
              <template slot="deselectLabel">{{
                dataLang['select_unselect']
              }}</template>
            </BaseSelect>
          </div>

          <div class="d-flex justify-content-center gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('selectOwner')"
            >
              {{ dataLang['reestr_fines_text48'] }}
            </div>

            <div
              class="btn btn-sm px-20 btn-primary fsc-14 text-uppercase"
              @click="addOwner"
            >
              {{ dataLang['tracker_text53'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>

    <BaseModal
      name="loadFileWithInfo"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="295"
      classes="bg-transparent shadow-none"
      @beforeClose="
        () => {
          ;(fileLoadResult = null), (filesDataFromLoading = {})
        }
      "
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('loadFileWithInfo')"
          ></button>
        </div>

        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p class="text-center fw-semi-bold mb-15">
            {{ dataLang['tracker_text47'] }}
          </p>
          <div class="fsc-14 mb-30 d-flex justify-content-center">
            <label class="btn btn-sm px-20 btn-primary fsc-14 text-uppercase">
              <input
                type="file"
                class="d-none"
                @change="onAttachFile"
                accept=".xlsx"
              />
              {{ dataLang['tracker_text48'] }}
            </label>
          </div>
          <p class="fsc-16 mb-30 text-center" v-if="fileLoadResult">
            {{ filesDataFromLoading }}
          </p>

          <div
            class="d-flex justify-content-center gap-10"
            v-if="fileLoadResult"
          >
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('loadFileWithInfo')"
            >
              {{ dataLang['reestr_fines_text48'] }}
            </div>

            <div
              class="btn btn-sm px-20 btn-primary fsc-14 text-uppercase"
              @click="onSetFile"
            >
              {{ dataLang['tracker_text51'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>

    <BaseModal
      name="acceptDeleteModal"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="295"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('acceptDeleteModal')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p class="text-center fw-semi-bold mb-35">
            {{ dataLang['tracker_text61'] }}
          </p>
          <!-- <div class="fsc-14">

            </div> -->

          <div class="d-flex justify-content-center mt-15 gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('acceptDeleteModal')"
            >
              {{ dataLang['reestr_fines_text48'] }}
            </div>

            <div
              class="btn btn-sm px-20 btn-primary fsc-14 text-uppercase"
              @click="deleteAcceptRow"
            >
              {{ dataLang['tracker_text51'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>

    <BaseModal
      name="selectRow"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="295"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('selectRow')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p class="text-center fw-semi-bold mb-35">
            {{ dataLang['tracker_text57'] }}
          </p>
          <!-- <div class="fsc-14">

            </div> -->

          <div class="d-flex justify-content-center mt-15 gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('selectRow')"
            >
              {{ dataLang['tracker_text55'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>

    <BaseModal
      name="deattachOwnerModal"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="295"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('deattachOwnerModal')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p class="text-center fw-semi-bold mb-35">
            {{ dataLang['tracker_text60'] }}
          </p>
          <!-- <div class="fsc-14">

            </div> -->

          <div class="d-flex justify-content-center mt-15 gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('deattachOwnerModal')"
            >
              {{ dataLang['tracker_text50'] }}
            </div>
            <div
              class="btn btn-sm px-20 btn-primary fsc-14 text-uppercase"
              @click="onDeattachOwner"
            >
              {{ dataLang['tracker_text51'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>

    <BaseModal
      name="successDeattachOwnerModal"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="295"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('successDeattachOwnerModal')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p class="text-center fw-semi-bold mb-35">
            {{ dataLang['tracker_text92'] }}
          </p>
          <!-- <div class="fsc-14">

            </div> -->

          <div class="d-flex justify-content-center mt-15 gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('successDeattachOwnerModal')"
            >
              {{ dataLang['tracker_text55'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>

    <BaseModal
      name="errorOfDelete"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="295"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('errorOfDelete')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p
            class="text-center fw-semi-bold mb-35"
            v-html="dataLang['tracker_text62']"
          ></p>

          <div class="d-flex justify-content-center mt-15 gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('errorOfDelete')"
            >
              {{ dataLang['tracker_text55'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>



    <BaseModal
      name="errorOfDeattachOwnerModal"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="295"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('errorOfDeattachOwnerModal')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p
            class="text-center fw-semi-bold mb-35"
            v-html="dataLang['tracker_text63']"
          ></p>

          <div class="d-flex justify-content-center mt-15 gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('errorOfDeattachOwnerModal')"
            >
              {{ dataLang['tracker_text55'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>

    <BaseModal
      name="successOfAddOwner"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="295"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('successOfAddOwner')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p
            class="text-center fw-semi-bold mb-35"
            v-html="dataLang['tracker_text59']"
          ></p>

          <div class="d-flex justify-content-center mt-15 gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('successOfAddOwner')"
            >
              {{ dataLang['tracker_text55'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>

    <BaseModal
      name="successAddTracker"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="295"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('successAddTracker')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p
            class="text-center fw-semi-bold mb-35"
            v-html="dataLang['tracker_text56']"
          ></p>

          <div class="d-flex justify-content-center mt-15 gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('successAddTracker')"
            >
              {{ dataLang['tracker_text55'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>

    <BaseModal
      name="successDeleteTracker"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="295"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('successDeleteTracker')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p
            class="text-center fw-semi-bold mb-35"
            v-html="dataLang['tracker_text54']"
          ></p>

          <div class="d-flex justify-content-center mt-15 gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('successDeleteTracker')"
            >
              {{ dataLang['tracker_text55'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>

    <BaseModal
      name="editTracker"
      width="640"
      :clickToClose="false"
      @before-close="selectsFromModal.owners = []"
      :shiftY="0.5"
      :adaptive="true"
      height="auto"
      @beforeClose="closemodal"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('editTracker')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p class="text-center fw-semi-bold">
            {{ dataLang['tracker_text91'] }}
          </p>
          <div class="fsc-14">
            <div class="d-flex gap-5 align-items-center">
              <div class="text-muted" style="width: 160px">
                {{ dataLang['tracker_text35'] }}
              </div>
              <div
                class="ps-10 border-start d-flex align-items-center"
                style="height: 33px"
              >
                {{ dataLang[dataForModal.gps_statuses__name] }}
              </div>
            </div>

            <div class="d-flex gap-5 align-items-center">
              <div class="text-muted" style="width: 160px">
                {{ dataLang['tracker_text36'] }}
              </div>
              <div
                class="ps-10 border-start d-flex align-items-center"
                style="height: 33px"
              >
                {{ dataForModal.gps__tracker_id }}
              </div>
            </div>
            <div class="d-flex gap-5 align-items-center">
              <div class="text-muted" style="width: 160px">
                {{ dataLang['tracker_text37'] }}
              </div>
              <div
                class="ps-10 border-start d-flex align-items-center"
                style="height: 33px"
              >
                {{ dataForModal.gps_typeunits__name }}
              </div>
            </div>
            <div class="d-flex gap-5 align-items-center">
              <div class="text-muted" style="width: 160px">
                {{ dataLang['tracker_text38'] }}
              </div>
              <div
                class="ps-10 border-start d-flex align-items-center"
                style="height: 33px"
              >
                {{ dataForModal.gps__imei }}
              </div>
            </div>
            <div class="d-flex gap-5 align-items-center">
              <div class="text-muted" style="width: 160px">
                {{ dataLang['tracker_text26'] }}
              </div>
              <div
                class="ps-10 border-start d-flex align-items-center"
                style="height: 33px"
              >
                {{ dataForModal.gps__gelios_unit_id }}
              </div>
            </div>
            <div class="d-flex gap-5 align-items-center">
              <div class="text-muted" style="width: 160px">
                {{ dataLang['tracker_text40'] }}
              </div>
              <div
                class="ps-10 border-start d-flex align-items-center"
                style="height: 33px"
              >
                {{ dataLang[dataForModal.vehicle_types__code] }}
              </div>
            </div>
            <div class="d-flex gap-5 align-items-center">
              <div class="text-muted" style="width: 160px">
                {{ dataLang['tracker_text41'] }}
              </div>
              <div
                class="ps-10 border-start d-flex align-items-center"
                style="height: 33px"
              >
                {{ dataForModal.vehicles__name }}
              </div>
            </div>
            <div class="d-flex gap-5 align-items-center">
              <div class="text-muted" style="width: 160px">
                {{ dataLang['tracker_text42'] }}
              </div>
              <div
                class="ps-10 border-start d-flex align-items-center"
                style="height: 33px"
              >
                {{ dataForModal.gps__sim_phone }}
              </div>
            </div>
            <div class="d-flex gap-5 align-items-center">
              <div class="text-muted" style="width: 160px">
                {{ dataLang['tracker_text43'] }}
              </div>
              <div
                class="ps-10 border-start d-flex align-items-center"
                style="height: 33px"
              >
                {{ dataForModal.gps__sim_operator }}
              </div>
            </div>
            <div class="d-flex gap-5 align-items-center">
              <div class="text-muted" style="width: 160px">
                {{ dataLang['tracker_text39'] }}
              </div>
              <div
                class="ps-10 border-start d-flex align-items-center"
                style="height: 33px"
              >
                {{ dataForModal.gps__odometr }}
              </div>
            </div>
            <div class="d-flex gap-5 align-items-center mb-10">
              <div class="text-muted" style="width: 160px">
                {{ dataLang['tracker_text46'] }}
              </div>
              <div
                class="ps-10 border-start d-flex align-items-center"
                style="height: 33px"
                v-html="selectsFromModal.owners.companies__name ? selectsFromModal.owners.companies__name : 'Не назначен'"
              >
              </div>
            </div>
            <div class="d-flex gap-5 align-items-center mb-10">
              <div class="text-muted" style="width: 160px">
                {{ dataLang['tracker_text44'] }}
              </div>
              <div class="d-flex align-items-center">
                <BaseInputNew
                  placeholder=""
                  class="wp-100"
                  :width="'415'"
                  input-name="gps__canpr"
                  :horizontalTitle="true"
                  @input="updateValue"
                  :value="dataForModal.gps__canpr"
                >
                </BaseInputNew>
              </div>
            </div>


            <div class="d-flex gap-5 align-items-center">
              <div class="text-muted" style="width: 160px">
                {{ dataLang['tracker_text45'] }}
              </div>
              <div class="d-flex align-items-center flex-grow-1">
                <BaseTextarea
                  placeholder=""
                  :disabled="true"
                  class="fsc-11 wp-100"
                  inputClass="fsc-11 wp-100"
                  @onInput="updateValue"
                  minHeight="54"
                  :maxLength="499"
                  :resize="false"
                  input-name="gps__comment_super_company"
                  :value="dataForModal.gps__comment_super_company"
                >
                </BaseTextarea>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-center mt-15 gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('editTracker')"
            >
              {{ dataLang['reestr_fines_text48'] }}
            </div>

            <div
              class="btn btn-sm px-20 btn-primary fsc-14 text-uppercase"
              @click="saveEditModal"
            >
              {{ dataLang['tracker_text53'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { VeTable } from 'vue-easytable'
import GeneralService from '@/api/GeneralService'
import httpInstance from '@/http-common'

export default {
  components: {
    VeTable
  },

  data() {
    return {
      localLoading: true,
      dataTable: [],
      activeSelectRowsMultiple: [],
      filter: [],
      updatedValues: {},
      computedAllPage: 1,
      currentPage: 1,
      nameValue: '',
      settingOneClick: {},
      activePagePagination: {},
      currentId: null,
      localLoadingModal: false,
      pincode: '',
      dataForModal: {},
      filesDataFromLoading: null,
      fileLoadResult: null,
      selectsDataForModal: {
        owners: []
      },
      selectsFromModal: {
        owners: []
      },
      selectsData: {
        trackers: [],
        statuses: [],
        typeunits: [],
        typeVehicles: []
      },
      selectsFilter: {
        trackers: [],
        statuses: [],
        typeunits: [],
        typeVehicles: []
      },
      gelleryList: [],
      eventCustomOption: {
        bodyRowEvents: ({ row, rowIndex }) => {
          return {
            dblclick: (event) => {
              console.log(event, row, rowIndex)
              this.$modal.show('editTracker')
              this.dataForModal = row

              this.selectsFromModal.owners.push(
                this.selectsDataForModal.owners.find(
                  (value) =>
                    value.companies__id == this.dataForModal.gps__company_id
                )
              )
            }
          }
        }
      },
      cellStyleOption: {
        bodyCellClass: ({ row, column, rowIndex }) => {
          if (column.field == 'companies__gelios_balance') {
            return row.settings?.companies__gelios_balance
          }
        }
      }
    }
  },
  methods: {
    selectedRowChange({ row, isSelected, selectedRowKeys }) {
      this.activeSelectRowsMultiple = selectedRowKeys
    },
    selectedAllChange({ row, isSelected, selectedRowKeys }) {
      this.activeSelectRowsMultiple = selectedRowKeys
    },

    updateValue(data) {
      console.log(data)
      this.updatedValues[data.name] = data.value
    },

    computedTranslate(values, tranaslate) {
      let keys = Object.keys(values)
      let tranaslateLocal = tranaslate
      keys.forEach((keyName) => {
        console.log(`\\${keyName}\\`)
        tranaslateLocal = tranaslateLocal.replace(
          `\\${keyName}\\`,
          values[keyName]
        )
      })
      console.log(tranaslateLocal)
      return tranaslateLocal
    },

    async onAttachFile(e) {
      let file = e.target.files[0]
      let formData = new FormData()
      formData.append('file', file)
      formData.append('type', 'gpsList')

      try {
        let dataFrom = await httpInstance.post('/storage', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        this.fileLoadResult = dataFrom.data.data.url
        let importFile = await httpInstance.post('/super/trackers/load', {
          file: this.fileLoadResult,
          save: 0
        })
        this.filesDataFromLoading = this.computedTranslate(
          { count: importFile.data.data.countRows },
          this.dataLang['tracker_text49']
        )
        console.log(importFile)
      } catch (error) {}
    },

    async onSetFile() {
      try {
        let importFile = await httpInstance.post('/super/trackers/load', {
          file: this.fileLoadResult,
          save: 1
        })
        this.$modal.hide('loadFileWithInfo')
        this.$modal.show('successAddTracker')
        this.loadData()
      } catch (e) {
        this.$toast.error(e.response.data.message)
        this.$modal.hide('loadFileWithInfo')
      }
    },

    async loadExel(urlToFetch) {
      let dataFrom = await httpInstance.get(urlToFetch, {
        responseType: 'blob'
      })
      const filename = new Date().toISOString()
      const url = window.URL.createObjectURL(dataFrom.data)
      const a = document.createElement('a')
      a.href = url
      a.download = `${filename}.xlsx`
      document.body.appendChild(a)
      a.click()
      a.remove()
    },

    saveEditModal() {
      GeneralService.edit(`/super/trackers/${this.dataForModal.gps__id}`, {
        ...this.updatedValues
      })
        .then((data) => {
          console.log(data)
          this.$modal.hide('editTracker')
          this.loadData()
        })
        .catch((e) => {
          console.log(e)
          this.selectsFromModal.owners = []
          this.$toast.error(e.response.data.message)
        })
    },

    onDeattachOwner() {
      GeneralService.edit(`/super/trackers/detach-owners`, {
        trackersIds: this.activeSelectRowsMultiple
      })
        .then((data) => {
          this.$modal.hide('deattachOwnerModal')
          this.$modal.show('successDeattachOwnerModal')
          this.loadData()
        })
        .catch((e) => {
          this.$modal.hide('deattachOwnerModal')
          this.$modal.show('errorOfDeattachOwnerModal')
        })
        .finally(() => {
          this.activeSelectRowsMultiple = []
        })
    },

    addOwner() {
      if (this.selectsFromModal.owners) {
        GeneralService.edit(`/super/trackers/attach-owners`, {
          trackersIds: this.activeSelectRowsMultiple,
          ownerId: this.selectsFromModal.owners.companies__id
        })
          .then((data) => {
            this.$modal.hide('selectOwner')
            this.$modal.show('successOfAddOwner')
            this.loadData()
          })
          .catch((e) => {
            console.log(e)
            this.selectsFromModal.owners = []
            this.activeSelectRowsMultiple = []
            this.$modal.hide('selectOwner')
            this.$modal.show('errorOfAddOwner')
          })
      }

      console.log(this.selectsFromModal.owners)
    },

    resetFilter() {
      this.$refs.mainTable.$el
        .querySelectorAll('input.base-input--new')
        .forEach((elem, index) => {
          elem.value = ''
        })
      this.$refs.topFilter
        .querySelectorAll('input.base-input--new')
        .forEach((elem, index) => {
          elem.value = ''
        })
      this.filter = []
      this.selectsFilter.trackers = []
      this.selectsFilter.statuses = []
      this.loadData()
    },

    // On click
    // Navigate to: "Авторизация / Данные введены";
    // Animate: Smart animate;
    // Spring: { mass: 1, stiffness: 400, damping: 30 };

    closemodal() {
      this.currentId = null
      this.updatedValues = {}
      this.dataForModal = {}
      this.activeSelectRowsMultiple = []
    },
    deleteActionAndModal() {
      if (this.activeSelectRowsMultiple.length) {
        this.$modal.show('acceptDelete')
      } else {
        this.$modal.show('withoutOptionsModal')
      }
    },
    pincodeModalHandler() {
      this.pincode = ''
      this.activeSelectRowsMultiple = []
    },
    deleteAcceptRow() {
      GeneralService.delete(`/super/trackers/` + this.activeSelectRowsMultiple)
        .then((data) => {
          this.loadData()
          this.$modal.hide('acceptDeleteModal')
          this.$modal.show('successDeleteTracker')
        })
        .catch((e) => {
          console.log(e)
          this.$modal.hide('acceptDeleteModal')
          this.$modal.show('errorOfDelete')
        })
    },
    loadData() {
      this.localLoading = true
      Promise.all([
        GeneralService.get(
          `/super/trackers/table?fields=gps_statuses__name,gps__status_id&uniqueBy=gps__status_id`
        )
          .then((data) => {
            this.selectsData.statuses = data.data.data.rows
            console.log('track', data)
          })
          .catch((e) => {
            console.log(e)
          }),

        GeneralService.get(`/super/companies`)
          .then((data) => {
            this.selectsDataForModal.owners = data.data.data.rows
            this.selectsDataForModal.owners.unshift({
              companies__name: 'Не выбрано',
              companies__id: null
            })
          })
          .catch((e) => {
            console.log(e)
          }),

        GeneralService.get(
          `/super/trackers/table?fields=gps_typeunits__id,gps_typeunits__name&uniqueBy=gps_typeunits__id`
        )
          .then((data) => {
            this.selectsData.typeunits = data.data.data.rows
          })
          .catch((e) => {
            console.log(e)
          }),
        GeneralService.get(
          `/super/trackers/table?fields=vehicle_types__code,vehicle_types__id&uniqueBy=vehicle_types__id`
        )
          .then((data) => {
            this.selectsData.typeVehicles = data.data.data.rows
          })
          .catch((e) => {
            console.log(e)
          })
        // /fines/table/main?fields=vehicle_fines_statuses__id,vehicle_fines_statuses__name&uniqueBy=vehicle_fines_statuses__id
      ]).then(() => {
        GeneralService.get(`/super/trackers/table`, {
          params: {
            ...this.paginationFinesStateParams,
            ...(this.filter.length && { where: JSON.stringify(this.filter) })
          }
        })
          .then((data) => {
            this.getFinesAll(data.data.data)
            console.log(data)
            this.dataTable = data.data.data.rows
            this.localLoading = false
          })
          .catch((e) => {
            console.log(e)
            this.$toast.error('Неизвестная ошибка при загрузке таблицы')
          })
      })
    },
    fillFilter(data) {
      let newValueFilter =
        data.type == 'like' ? `;percent;${data.value};percent;` : data.value
      if (!this.filter.find((x) => x.field == data.name)) {
        this.filter.push({
          field: data.name,
          value: newValueFilter,
          ...(data.type == 'like' && { operator: 'like' }),
          ...(data.operator && { operator: data.operator })
        })
      } else {
        this.filter[this.filter.findIndex((x) => x.field == data.name)].value =
          newValueFilter
      }
    },

    prepareLabelsForDefaultSelects(data, keyDataFromFetch, toTranslate) {
      for (const key in data) {
        data[key].forEach((element) => {
          element['label'] = element[Object.keys(element).pop()]
          element['key'] = key
          element['value'] = Object.keys(element)[0]
          element['selectName'] = keyDataFromFetch
        })
      }
      return data
    },
    ...mapActions('finesPagination', ['getFinesAll'])
  },
  mounted() {},
  created() {
    Promise.all([]).then(() => {
      this.loadData()
    })
  },
  computed: {
    ...mapState('translationModule', ['dataLang']),
    ...mapState('userSettingModule', ['userSetting']),
    ...mapState('finesPagination', ['paginationFinesStateParams']),
    ...mapGetters('finesPagination', ['numPages']),
    columns() {
      return [
        {
          field: '',
          key: 'a6',
          type: 'checkbox',
          title: '',
          width: 5,
          align: 'center'
        },
        {
          field: 'gps_statuses__name',
          key: '1723',
          title: this.dataLang['tracker_text22'],
          align: 'center',
          width: '12%',
          ellipsis: {
            showTitle: false,
            lineClamp: 1
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{this.dataLang[row.gps_statuses__name]}</span>
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <BaseSelect
                  vModel={this.selectsFilter.statuses}
                  onInput={(e, w) => {
                    console.log(e)
                    this.fillFilter({
                      name: 'gps__status_id',
                      value: e.gps__status_id
                    })
                  }}
                  style={'width: 100%'}
                  placeholder={''}
                  id={'ads__id'}
                  selectLabel={''}
                  deselectLabel={''}
                  customLabel={({ gps_statuses__name }) => {
                    return `${this.dataLang[gps_statuses__name]} `
                  }}
                  options={this.selectsData.statuses}
                ></BaseSelect>
              </div>
            )
          }
        },
        {
          field: 'gps__tracker_id',
          key: '163',
          title: this.dataLang['tracker_text23'],
          align: 'center',
          width: '7%',
          ellipsis: {
            showTitle: false,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
        },
        {
          field: 'gps_typeunits__name',
          key: '1533',
          title: this.dataLang['tracker_text24'],
          align: 'center',
          width: '10%',

          ellipsis: {
            showTitle: false,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <div style={'height: 19.5px'}></div>
              </div>
            )
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <BaseSelect
                  vModel={this.selectsFilter.typeunits}
                  onInput={(e, w) => {
                    console.log(e)
                    this.fillFilter({
                      name: 'gps_typeunits__id',
                      value: e.gps_typeunits__id
                    })
                  }}
                  style={'width: 100%'}
                  placeholder={''}
                  id={'ads__id'}
                  selectLabel={''}
                  deselectLabel={''}
                  customLabel={({ gps_typeunits__name }) => {
                    return `${gps_typeunits__name} `
                  }}
                  options={this.selectsData.typeunits}
                ></BaseSelect>
              </div>
            )
          }
        },
        {
          field: 'gps__imei',
          key: '172',
          title: this.dataLang['tracker_text25'],
          align: 'center',
          width: '12%',
          ellipsis: {
            showTitle: false,
            lineClamp: 1
          },

          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
        },
        {
          field: 'gps__gelios_unit_id',
          key: '13746',
          title: this.dataLang['tracker_text26'],
          align: 'center',
          width: '6%',

          ellipsis: {
            showTitle: false,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
        },
        {
          field: 'vehicle_types__code',
          key: '16531',
          title: this.dataLang['tracker_text27'],
          align: 'center',
          width: '8%',

          ellipsis: {
            showTitle: false,
            lineClamp: 1
          },

          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <BaseSelect
                  vModel={this.selectsFilter.typeVehicles}
                  onInput={(e, w) => {
                    console.log(e)
                    this.fillFilter({
                      name: 'vehicle_types__id',
                      value: e.vehicle_types__id
                    })
                  }}
                  style={'width: 100%'}
                  placeholder={''}
                  id={'ads__id'}
                  selectLabel={''}
                  deselectLabel={''}
                  customLabel={({ vehicle_types__code }) => {
                    return `${this.dataLang[vehicle_types__code]} `
                  }}
                  options={this.selectsData.typeVehicles}
                ></BaseSelect>
              </div>
            )
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{this.dataLang[row.vehicle_types__code]}</span>
          }
        },
        {
          field: 'vehicles__name',
          key: '163111',
          title: this.dataLang['tracker_text28'],
          align: 'center',
          width: '8%',

          ellipsis: {
            showTitle: false,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <div style={'height: 19.5px'}></div>
              </div>
            )
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>
                {row.vehicles__name} {row.vehicles__gosnumber}
              </span>
            )
          }
        },
        {
          field: 'gps__canpr',
          key: '16315',
          title: this.dataLang['tracker_text29'],
          align: 'center',
          width: '8%',

          ellipsis: {
            showTitle: false,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>

                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
        },
        {
          field: 'gps__odometr',
          key: '12631',
          title: this.dataLang['tracker_text30'],
          align: 'center',
          width: '8%',

          ellipsis: {
            showTitle: false,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>

                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
        },
        {
          field: 'gps__company_id',
          key: '16311',
          title: this.dataLang['tracker_text31'],
          align: 'center',
          width: '8%',

          ellipsis: {
            showTitle: false,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>

                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
        },
        {
          field: 'companies__gelios_balance',
          key: '16331',
          title: this.dataLang['tracker_text15'],
          align: 'center',
          width: '8%',

          ellipsis: {
            showTitle: false,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>

                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
        },
        {
          field: 'gps__comment_super_company',
          key: '16431',
          title: this.dataLang['tracker_text32'],
          align: 'center',
          width: '8%',

          ellipsis: {
            showTitle: false,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>

                <div style={'height: 19.5px'}></div>
              </div>
            )
          }
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.indicator {
  display: flex;
  padding: 5px 0;
  border-radius: 5px;
  background-color: #23b14d;
  color: white;
  width: 80px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  &--warn {
    background-color: #ff3a20;
  }
}
</style>

<template>
  <div class="position-relative">
    <BaseTopLine> </BaseTopLine>
    <div class="position-relative">
      <vue-tabs
        v-model="activateTab"
        @tab-change="
          () => {
            resetFilter()
          }
        "
        class="border-top"
      >
        <v-tab :title="dataLang['tracker_text13']" />
        <v-tab :title="dataLang['tracker_text14']" />
      </vue-tabs>
      <div class="top-line-custom" v-if="aboutPageInfo">
        <span
          v-html="
            computedTranslate(
              {
                companies__gelios_tariff:
                  aboutPageInfo.data.companies__gelios_tariff
              },
              dataLang['tracker_text93']
            )
          "
        ></span>
        <span
          v-html="
            computedTranslate(
              {
                companies__gelios_balance:
                  aboutPageInfo.data.companies__gelios_balance
              },
              dataLang['tracker_text94']
            )
          "
        ></span>
        <span
          class="text-decoration-underline cursor-pointer"
          @click="$modal.show('addBalance')"
        >
          {{ dataLang['tracker_text16'] }}
        </span>
      </div>
    </div>
    <div
      class="py-10 px-15 d-flex gap-15 align-items-end justify-content-between border-top"
      style="background-color: #1d1e2c"
      ref="topFilter"
    >
      <div class="d-flex gap-5 align-items-center">
        <span class="text-white fsc-11">{{ dataLang['tracker_text2'] }}</span>
        <div class="d-flex gap-5 flex-grow-1">
          <BaseInputNew
            placeholder=""
            style="width: 130px"
            @input="fillFilter"
            inputClass="bg-transparent text-white"
            :additionalParams="{ type: 'like' }"
            inputName="gps__tracker_id"
            value=""
            nameSign=""
          />
          <div class="btn btn-sm btn-primary fsc-11 px-20" @click="loadData">
            {{ dataLang['reestr_fines_text9'] }}
          </div>
        </div>
      </div>
      <div class="d-flex gap-5 align-items-center">
        <span class="text-white fsc-11">{{ dataLang['tracker_text3'] }}</span>
        <div class="d-flex gap-5 flex-grow-1">
          <BaseInputNew
            placeholder=""
            style="width: 130px"
            @input="fillFilter"
            inputClass="bg-transparent text-white"
            :additionalParams="{ type: 'like' }"
            inputName="gps__imei"
            value=""
            nameSign=""
          />
          <div class="btn btn-sm btn-primary fsc-11 px-20" @click="loadData">
            {{ dataLang['reestr_fines_text9'] }}
          </div>
        </div>
      </div>
      <div class="d-flex gap-5 align-items-center">
        <span class="text-white fsc-11">{{ dataLang['tracker_text4'] }}</span>
        <div class="d-flex gap-5 flex-grow-1">
          <BaseInputNew
            placeholder=""
            style="width: 130px"
            @input="fillFilter"
            inputClass="bg-transparent text-white"
            :additionalParams="{ type: 'like' }"
            inputName="vehicles__gosnumber"
            value=""
            nameSign=""
          />
          <div class="btn btn-sm btn-primary fsc-11 px-20" @click="loadData">
            {{ dataLang['reestr_fines_text9'] }}
          </div>
        </div>
      </div>
      <div class="d-flex gap-5 align-items-center">
        <div class="d-flex gap-5 flex-grow-1">
          <div
            class="btn btn-sm btn-primary fsc-11 px-20"
            @click="
              () => {
                resetFilter()
                fillFilter({
                  name: 'gps__vehicle_id',
                  value: '',
                  mod: 'isNull'
                })
                loadData()
              }
            "
          >
            {{ dataLang['tracker_text6'] }}
          </div>
          <div
            class="btn btn-sm btn-primary fsc-11 px-20"
            @click="
              () => {
                resetFilter()
                fillFilter({
                  name: 'gps__vehicle_id',
                  value: '',
                  mod: 'isNotNull'
                })
                loadData()
              }
            "
          >
            {{ dataLang['tracker_text5'] }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="position-relative h-100 container-fluid"
      style="min-height: 250px"
    >
      <BaseLoader v-if="localLoading"></BaseLoader>
      <div
        class="d-flex justify-content-between align-items-center mt-15 mb-10"
      >
        <div class="d-flex gap-10">
          <div
            class="base-button base-button--action"
            @click="
              () =>
                activeSelectRowsMultiple.length
                  ? $modal.show('acceptToggleTracker')
                  : $modal.show('selectRow')
            "
            v-html="
              activateTab == dataLang['tracker_text13']
                ? dataLang['tracker_text7']
                : dataLang['tracker_text8']
            "
          ></div>
          <div
            class="base-button base-button--action"
            @click="
              () =>
                activeSelectRowsMultiple.length
                  ? $modal.show('acceptDeleteModal')
                  : $modal.show('selectRow')
            "
          >
            <img
              :src="require(`@/assets/icons/adddel.svg`)"
              width="13.5"
              height="13.5"
            />
            {{ dataLang['tracker_text9'] }}
          </div>

          <a
            href="https://docs.google.com/spreadsheets/d/1vkZuc5tkFOzLz65n0fcsKZFy8zkNlbo9sCM5zfpq6f8/edit#gid=1624217125"
            target="_blank"
            class="btn btn-sm text-white fsc-11"
            style="background-color: #0056af"
          >
            {{ dataLang['tracker_text10'] }}
          </a>
        </div>

        <div class="d-flex gap-10">
          <div
            class="btn btn-sm btn-primary fsc-11 px-20"
            @click="$modal.show('buyTracker')"
          >
            {{ dataLang['tracker_text11'] }}
          </div>
          <div
            class="btn btn-sm btn-primary fsc-11 px-20"
            @click="$modal.show('buyTranslateTracker')"
          >
            {{ dataLang['tracker_text12'] }}
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-between align-items-center mt-15 mb-10"
      >
        <div class="d-flex gap-15 align-items-center">
          <div
            class="btn btn-sm btn-thrity text-white fsc-14"
            @click="loadData(1)"
          >
            {{ dataLang['register_btn_filter_on'] }}
          </div>
          <div
            class="text-decoration-underline fsc-14 text-dark"
            style="cursor: pointer"
            @click="resetFilter"
          >
            {{ dataLang['register_btn_filter_off'] }}
          </div>
        </div>
        <BasePagination
          :page-count="numPages"
          :pagination-params="paginationFinesStateParams"
          :showLimitInput="false"
          :page-range="10"
          :margin-pages="0"
          :break-view-class="''"
          :click-handler="loadData"
          :prev-text="''"
          :next-text="''"
          :container-class="'custom-pagination'"
          :page-class="'custom-pagination__item'"
          :pageLinkClass="'custom-pagination__item-link'"
        >
        </BasePagination>
      </div>
      <div class="overflow-auto" style="min-height: 400px">
        <ve-table
          style="width: 100%"
          :columns="columns"
          :table-data="dataTable"
          :border-x="true"
          :border-y="true"
          row-key-field-name="gps__id"
          ref="mainTable"
          :eventCustomOption="eventCustomOption"
          v-if="!localLoading"
          :checkbox-option="{
            selectedRowChange: selectedRowChange,
            selectedAllChange: selectedAllChange,
            hideSelectAll: false
          }"
          :clickHighlight="false"
          :cell-style-option="cellStyleOption"
          :rowStyleOption="{
            hoverHighlight: true
          }"
          :cellSelectionOption="{ enable: false }"
        >
        </ve-table>
      </div>
    </div>

    <BaseModal
      name="acceptToggleTracker"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="295"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('acceptToggleTracker')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p class="text-center mb-35">
            <span v-if="activateTab == dataLang['tracker_text13']">
              Выключить выбранные трекеры?
            </span>
            <span v-if="activateTab == dataLang['tracker_text14']">
              Включить выбранные трекеры?</span
            >
          </p>

          <div class="d-flex justify-content-center gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('acceptToggleTracker')"
            >
              {{ dataLang['tracker_text50'] }}
            </div>

            <div
              class="btn btn-sm px-20 btn-primary fsc-14 text-uppercase"
              @click="toggleTrackerHandler"
            >
              {{ dataLang['tracker_text51'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>

    <BaseModal
      name="acceptDeleteModal"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="295"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('acceptDeleteModal')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p class="text-center fw-semi-bold mb-35">
            {{ dataLang['tracker_text61'] }}
          </p>

          <div class="d-flex justify-content-center mt-15 gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('acceptDeleteModal')"
            >
              {{ dataLang['reestr_fines_text48'] }}
            </div>

            <div
              class="btn btn-sm px-20 btn-primary fsc-14 text-uppercase"
              @click="deleteAcceptRow"
            >
              {{ dataLang['tracker_text51'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>

    <BaseModal
      name="selectRow"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="295"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('selectRow')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p class="text-center fw-semi-bold mb-35">
            {{ dataLang['tracker_text57'] }}
          </p>

          <div class="d-flex justify-content-center mt-15 gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('selectRow')"
            >
              {{ dataLang['tracker_text55'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
    <BaseModal
      name="buyTracker"
      width="420"
      :clickToClose="false"
      @before-close=""
      :shiftY="0.5"
      :adaptive="true"
      height="295"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('buyTracker')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p class="text-center fw-semi-bold mb-15">
            {{ dataLang['tracker_text65'] }}
          </p>
          <div class="d-flex justify-content-center">
            <div class="d-flex align-items-center" style="width: 210px">
              <BaseInputNew
                placeholder=""
                class="wp-100"
                :width="'115'"
                input-name="gps__sim_phone"
                :horizontalTitle="true"
                @input="
                  ({ value }) => {
                    newValueCustomModal = value
                  }
                "
                :name-sign="dataLang['tracker_text66']"
              >
              </BaseInputNew>
            </div>
          </div>

          <div class="d-flex justify-content-center mt-30 gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('buyTracker')"
            >
              {{ dataLang['tracker_text50'] }}
            </div>
            <div
              class="btn btn-sm px-20 btn-primary fsc-14 text-uppercase"
              @click="onBuyTracker"
            >
              {{ dataLang['tracker_text69'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>

    <BaseModal
      name="buyTranslateTracker"
      width="420"
      :clickToClose="false"
      @before-close=""
      :shiftY="0.5"
      :adaptive="true"
      height="295"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('buyTranslateTracker')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p class="text-center fw-semi-bold mb-15">
            {{ dataLang['tracker_text68'] }}
          </p>
          <div class="d-flex justify-content-center">
            <div class="d-flex align-items-center" style="width: 210px">
              <BaseInputNew
                placeholder=""
                class="wp-100"
                :width="'115'"
                input-name="gps__sim_phone"
                :horizontalTitle="true"
                @input="
                  ({ value }) => {
                    newValueCustomModal = value
                  }
                "
                :name-sign="dataLang['tracker_text66']"
              >
              </BaseInputNew>
            </div>
          </div>

          <div class="d-flex justify-content-center mt-30 gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('buyTranslateTracker')"
            >
              {{ dataLang['tracker_text50'] }}
            </div>
            <div
              class="btn btn-sm px-20 btn-primary fsc-14 text-uppercase"
              @click="onBuyTranslateTracker"
            >
              {{ dataLang['tracker_text69'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>

    <BaseModal
      name="errorOfActivateTracker"
      width="420"
      :clickToClose="false"
      @before-close="() => (dataOfErrors = null)"
      :shiftY="0.5"
      :adaptive="true"
      height="295"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('errorOfActivateTracker')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p
            class="text-center mb-35"
            v-if="dataOfErrors"
            v-html="computedTranslate(dataOfErrors, dataLang['tracker_text81'])"
          ></p>

          <div class="d-flex justify-content-center gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('errorOfActivateTracker')"
            >
              {{ dataLang['tracker_text55'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>

    <BaseModal
      name="successOfBuyTracker"
      width="420"
      :clickToClose="false"
      @before-close="() => (newValueCustomModal = '')"
      :shiftY="0.5"
      :adaptive="true"
      height="295"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('successOfBuyTracker')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p
            class="text-center fw-semi-bold mb-35"
            v-html="dataLang['tracker_text70']"
          ></p>

          <div class="d-flex justify-content-center gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('successOfBuyTracker')"
            >
              {{ dataLang['tracker_text55'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>

    <BaseModal
      name="addBalance"
      width="420"
      :clickToClose="false"
      @before-close=""
      :shiftY="0.5"
      :adaptive="true"
      height="295"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('addBalance')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p class="text-center fw-semi-bold mb-15">
            {{ dataLang['tracker_text86'] }}
          </p>
          <div class="d-flex justify-content-center">
            <div class="d-flex align-items-center" style="width: 210px">
              <BaseInputNew
                placeholder=""
                class="wp-100"
                :width="'115'"
                input-name="gps__sim_phone"
                :horizontalTitle="true"
                @input="
                  ({ value }) => {
                    newValueCustomModal = value
                  }
                "
                :name-sign="dataLang['tracker_text87']"
              >
              </BaseInputNew>
            </div>
          </div>

          <div class="d-flex justify-content-center mt-30 gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('addBalance')"
            >
              {{ dataLang['tracker_text50'] }}
            </div>
            <div
              class="btn btn-sm px-20 btn-primary fsc-14 text-uppercase"
              @click="onAddBalance"
            >
              {{ dataLang['tracker_text88'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>

    <BaseModal
      name="errorOfAddBalance"
      width="420"
      :clickToClose="false"
      @before-close="() => (newValueCustomModal = '')"
      :shiftY="0.5"
      :adaptive="true"
      height="295"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('errorOfAddBalance')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p
            class="text-center fw-semi-bold mb-35"
            v-html="dataLang['tracker_text89']"
          ></p>

          <div class="d-flex justify-content-center gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('errorOfAddBalance')"
            >
              {{ dataLang['tracker_text55'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
    <BaseModal
      name="errorOfAddBalance"
      width="420"
      :clickToClose="false"
      @before-close="() => (newValueCustomModal = '')"
      :shiftY="0.5"
      :adaptive="true"
      height="295"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('errorOfAddBalance')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p
            class="text-center fw-semi-bold mb-35"
            v-html="dataLang['tracker_text89']"
          ></p>

          <div class="d-flex justify-content-center gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('errorOfAddBalance')"
            >
              {{ dataLang['tracker_text55'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>

    <BaseModal
      name="successOfAddBalance"
      width="420"
      :clickToClose="false"
      @before-close="() => (newValueCustomModal = '')"
      :shiftY="0.5"
      :adaptive="true"
      height="295"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('errorOfAddBalance')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p
            class="text-center fw-semi-bold mb-35"
            v-html="
              computedTranslate(
                { count: newValueCustomModal },
                dataLang['tracker_text90']
              )
            "
          ></p>

          <div class="d-flex justify-content-center gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('successOfAddBalance')"
            >
              {{ dataLang['tracker_text55'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>

    <BaseModal
      name="errorOfBalance"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="295"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('errorOfBalance')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p
            class="text-center fw-semi-bold mb-35"
            v-html="dataLang['tracker_text64']"
          ></p>

          <div class="d-flex justify-content-center mt-15 gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('errorOfBalance')"
            >
              {{ dataLang['tracker_text55'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>

    <BaseModal
      name="successOfDisabledTracker"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="295"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('successOfDisabledTracker')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p
            class="text-center fw-semi-bold mb-35"
            v-html="dataLang['tracker_text83']"
          ></p>

          <div class="d-flex justify-content-center mt-15 gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('successOfDisabledTracker')"
            >
              {{ dataLang['tracker_text55'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>

    <BaseModal
      name="successOfActivateTracker"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="450"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('successOfActivateTracker')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p class="text-center mb-35" v-html="dataLang['tracker_text82']"></p>

          <div class="d-flex justify-content-center gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('successOfActivateTracker')"
            >
              {{ dataLang['tracker_text55'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>

    <BaseModal
      name="successAddTracker"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="295"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('successAddTracker')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p
            class="text-center fw-semi-bold mb-35"
            v-html="dataLang['tracker_text56']"
          ></p>

          <div class="d-flex justify-content-center mt-15 gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('successAddTracker')"
            >
              {{ dataLang['tracker_text55'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>

    <BaseModal
      name="successDeleteTracker"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="295"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('successDeleteTracker')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p
            class="text-center fw-semi-bold mb-35"
            v-html="dataLang['tracker_text54']"
          ></p>

          <div class="d-flex justify-content-center mt-15 gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('successDeleteTracker')"
            >
              {{ dataLang['tracker_text55'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>

    <BaseModal
      name="errorDeleteTracker"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="295"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('errorDeleteTracker')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p
            class="text-center fw-semi-bold mb-35"
            v-html="dataLang['tracker_text62']"
          ></p>

          <div class="d-flex justify-content-center mt-15 gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('errorDeleteTracker')"
            >
              {{ dataLang['tracker_text55'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>

    <BaseModal
      name="editTracker"
      width="640"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="auto"
      @before-close="onCloseModal"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('editTracker')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p class="text-center fw-semi-bold">
            {{ dataLang['tracker_text91'] }}
          </p>
          <div class="fsc-14">
            <div class="d-flex gap-5 align-items-center">
              <div class="text-muted" style="width: 160px">
                {{ dataLang['tracker_text35'] }}
              </div>
              <div
                class="ps-10 border-start d-flex align-items-center"
                style="height: 33px"
              >
                {{ dataLang[dataForModal.gps_statuses__name] }}
              </div>
            </div>

            <div class="d-flex gap-5 align-items-center">
              <div class="text-muted" style="width: 160px">
                {{ dataLang['tracker_text36'] }}
              </div>
              <div
                class="ps-10 border-start d-flex align-items-center"
                style="height: 33px"
              >
                {{ dataForModal.gps__tracker_id }}
              </div>
            </div>
            <div class="d-flex gap-5 align-items-center">
              <div class="text-muted" style="width: 160px">
                {{ dataLang['tracker_text37'] }}
              </div>
              <div
                class="ps-10 border-start d-flex align-items-center"
                style="height: 33px"
              >
                {{ dataForModal.gps_typeunits__name }}
              </div>
            </div>
            <div class="d-flex gap-5 align-items-center">
              <div class="text-muted" style="width: 160px">
                {{ dataLang['tracker_text38'] }}
              </div>
              <div
                class="ps-10 border-start d-flex align-items-center"
                style="height: 33px"
              >
                {{ dataForModal.gps__imei }}
              </div>
            </div>
            <div class="d-flex gap-5 align-items-center">
              <div class="text-muted" style="width: 160px">
                {{ dataLang['tracker_text26'] }}
              </div>
              <div
                class="ps-10 border-start d-flex align-items-center"
                style="height: 33px"
              >
                {{ dataForModal.gps__gelios_unit_id }}
              </div>
            </div>
            <div class="d-flex gap-5 align-items-center">
              <div class="text-muted" style="width: 160px">
                {{ dataLang['tracker_text40'] }}
              </div>
              <div
                class="ps-10 border-start d-flex align-items-center"
                style="height: 33px"
              >
                {{
                  dataLang[
                    selectsDataForModal.typeVehicles.find(
                      (value) =>
                        value.vehicle_types__id ==
                        this.dataForModal.vehicle_types__id
                    )?.vehicle_types__code
                  ]
                }}
              </div>
            </div>
            <div class="d-flex gap-5 align-items-center mb-10">
              <div class="text-muted" style="width: 160px">
                {{ dataLang['tracker_text39'] }}
              </div>
              <div
                class="ps-10 border-start d-flex align-items-center"
                style="height: 33px"
              >
                {{ dataForModal.gps__odometr }}
              </div>
            </div>
            <div class="d-flex gap-5 align-items-center mb-10">
              <div class="text-muted" style="width: 160px">
                {{ dataLang['tracker_text41'] }}
              </div>
              <div class="d-flex align-items-center">
                <BaseSelect
                  class=""
                  @input="
                    (e) =>
                      updateValue({
                        value: e.vehicles__id,
                        name: 'gps__vehicle_id'
                      })
                  "
                  placeholder=""
                  selectLabel=""
                  deselectLabel=""
                  v-model="selectsFromModal.objectsVehicle"
                  :customLabel="
                    ({ vehicles__name, vehicles__gosnumber, vehicles__id }) => {
                      return vehicles__id
                        ? `${vehicles__name} ${vehicles__gosnumber}`
                        : vehicles__name
                    }
                  "
                  :options="objectsVehiclesNew"
                  style="width: 415px"
                  :maxHeight="100"
                  openDirection="top"
                >
                  <template slot="noOptions">{{
                    dataLang['select_empty']
                  }}</template>
                  <template slot="noResult">{{
                    dataLang['select_search_empty']
                  }}</template>
                  <template slot="selectedLabel">{{
                    dataLang['select_selected']
                  }}</template>
                  <template slot="deselectLabel">{{
                    dataLang['select_unselect']
                  }}</template>
                </BaseSelect>
              </div>
            </div>

            <div class="d-flex gap-5 align-items-center mb-10">
              <div class="text-muted" style="width: 160px">
                {{ dataLang['tracker_text42'] }}
              </div>
              <div class="d-flex align-items-center">
                <BaseInputNew
                  placeholder=""
                  class="wp-100"
                  :width="'415'"
                  input-name="gps__sim_phone"
                  :horizontalTitle="true"
                  @input="updateValue"
                  :value="dataForModal.gps__sim_phone"
                  inputMask="+{7}(000)000-00-00"
                  maxLength="16"
                >
                </BaseInputNew>
              </div>
            </div>

            <div class="d-flex gap-5 align-items-center mb-10">
              <div class="text-muted" style="width: 160px">
                {{ dataLang['tracker_text43'] }}
              </div>
              <div class="d-flex align-items-center">
                <BaseInputNew
                  placeholder=""
                  class="wp-100"
                  :width="'415'"
                  input-name="gps__sim_operator"
                  :horizontalTitle="true"
                  @input="updateValue"
                  :value="dataForModal.gps__sim_operator"
                >
                </BaseInputNew>
              </div>
            </div>
            <div class="d-flex gap-5 align-items-center mb-10">
              <div class="text-muted" style="width: 160px">
                {{ dataLang['tracker_text44'] }}
              </div>
              <div class="d-flex align-items-center">
                <BaseInputNew
                  placeholder=""
                  class="wp-100"
                  :width="'415'"
                  input-name="gps__canpr"
                  :horizontalTitle="true"
                  @input="updateValue"
                  :value="dataForModal.gps__canpr"
                >
                </BaseInputNew>
              </div>
            </div>

            <div class="d-flex gap-5 align-items-center">
              <div class="text-muted" style="width: 160px">
                {{ dataLang['tracker_text45'] }}
              </div>
              <div class="d-flex align-items-center flex-grow-1">
                <BaseTextarea
                  placeholder=""
                  :disabled="true"
                  class="fsc-11 wp-100"
                  inputClass="fsc-11 wp-100"
                  @onInput="updateValue"
                  minHeight="54"
                  :maxLength="499"
                  :resize="false"
                  input-name="gps__comment_company"
                  :value="dataForModal.gps__comment_company"
                >
                </BaseTextarea>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-center mt-15 gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('editTracker')"
            >
              {{ dataLang['reestr_fines_text48'] }}
            </div>

            <div
              class="btn btn-sm px-20 btn-primary fsc-14 text-uppercase"
              @click="saveEditModal"
            >
              {{ dataLang['tracker_text53'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { VeTable } from 'vue-easytable'
import GeneralService from '@/api/GeneralService'
import httpInstance from '@/http-common'
import { VueTabs, VTab } from 'vue-nav-tabs/dist/vue-tabs.js'

export default {
  components: {
    VeTable,
    VueTabs,
    VTab
  },

  data() {
    return {
      localLoading: true,
      dataTable: [],
      activeSelectRowsMultiple: [],
      filter: [],
      activateTab: '',
      updatedValues: {},
      computedAllPage: 1,
      currentPage: 1,
      nameValue: '',
      settingOneClick: {},
      activePagePagination: {},
      newValueCustomModal: '',
      currentId: null,
      localLoadingModal: false,
      objectsVehiclesNew: [],
      pincode: '',
      dataForModal: {},
      aboutPageInfo: null,
      fileLoadResult: null,
      dataOfErrors: null,
      selectsDataForModal: {
        owners: [],
        typeVehicles: [],
        objectsVehicle: []
      },
      selectsFromModal: {
        owners: [],
        typeVehicles: [],
        objectsVehicle: []
      },
      selectsData: {
        trackers: [],
        statuses: [],
        typeunits: []
      },
      selectsFilter: {
        trackers: [],
        statuses: [],
        typeunits: []
      },
      gelleryList: [],
      eventCustomOption: {
        bodyRowEvents: ({ row, rowIndex }) => {
          return {
            dblclick: (event) => {
              this.$modal.show('editTracker')
              this.dataForModal = row

              this.selectsFromModal.typeVehicles.push(
                this.selectsDataForModal.typeVehicles.find(
                  (value) =>
                    value.vehicle_types__id ==
                    this.dataForModal.vehicle_types__id
                ) || {
                  vehicle_types__code:
                    this.selectsDataForModal.typeVehicles[0]
                      .vehicle_types__code,
                  vehicle_types__id: null
                }
              )
              // console.log(this.dataForModal.vehicles__id, this.objectsVehiclesNew.findIndex(
              //     (el) =>
              //     el.vehicles__gosnumber === this.dataForModal.vehicles__gosnumber
              //   ));
              // this.objectsVehiclesNew.forEach(el => console.log(el.vehicles__gosnumber))
              if (this.dataForModal.vehicles__id) {
                this.selectsFromModal.objectsVehicle = [
                  {
                    vehicles__gosnumber: this.dataForModal.vehicles__gosnumber,
                    vehicles__id: this.dataForModal.vehicles__id,
                    vehicles__name: this.dataForModal.vehicles__name
                  }
                ]
              } else {
                this.selectsFromModal.objectsVehicle = [
                  {
                    vehicles__gosnumber: '',
                    vehicles__id: null,
                    vehicles__name: 'Не назначен'
                  }
                ]
              }

              // console.log(this.objectsVehiclesNew);
            }
          }
        }
      },
      cellStyleOption: {
        bodyCellClass: ({ row, column, rowIndex }) => {
          if (column.field == 'companies__gelios_balance') {
            return row.settings?.companies__gelios_balance
          }
        }
      }
    }
  },
  methods: {
    selectedRowChange({ row, isSelected, selectedRowKeys }) {
      this.activeSelectRowsMultiple = selectedRowKeys
    },
    selectedAllChange({ row, isSelected, selectedRowKeys }) {
      this.activeSelectRowsMultiple = selectedRowKeys
    },

    updateValue(data) {
      this.updatedValues[data.name] = data.value
    },

    computedTranslate(values, tranaslate) {
      let keys = Object.keys(values)
      let tranaslateLocal = tranaslate
      keys.forEach((keyName) => {
        tranaslateLocal = tranaslateLocal.replace(
          `\\${keyName}\\`,
          values[keyName]
        )
      })
      return tranaslateLocal
    },

    async loadExel(urlToFetch) {
      let dataFrom = await httpInstance.get(urlToFetch, {
        responseType: 'blob'
      })
      const filename = new Date().toISOString()
      const url = window.URL.createObjectURL(dataFrom.data)
      const a = document.createElement('a')
      a.href = url
      a.download = `${filename}.xlsx`
      document.body.appendChild(a)
      a.click()
      a.remove()
    },

    saveEditModal() {
      GeneralService.edit(`/trackers/${this.dataForModal.gps__id}`, {
        ...this.updatedValues
      })
        .then((data) => {
          this.$modal.hide('editTracker')
          this.loadData()
        })
        .catch((e) => {
          this.selectsFromModal.owners = []
          this.$toast.error(e.response.data.message)
        })
    },

    onBuyTracker() {
      if (this.newValueCustomModal) {
        GeneralService.editOrCreate(`/bids`, {
          request_services__type_service_id: 10,
          count: this.newValueCustomModal
        })
          .then((data) => {
            this.$modal.hide('buyTracker')
            this.$modal.show('successOfBuyTracker')
            this.loadData()
          })
          .catch((e) => {
            this.$modal.hide('buyTracker')
            this.$toast.error(e.response.data.message)
          })
      }
    },

    onBuyTranslateTracker() {
      if (this.newValueCustomModal) {
        GeneralService.editOrCreate(`/bids`, {
          request_services__type_service_id: 11,
          count: this.newValueCustomModal
        })
          .then((data) => {
            this.$modal.hide('buyTranslateTracker')
            this.$modal.show('successOfBuyTracker')
            this.loadData()
          })
          .catch((e) => {
            this.$modal.hide('buyTranslateTracker')
            this.$toast.error(e.response.data.message)
          })
      }
    },

    onAddBalance() {
      if (this.newValueCustomModal) {
        GeneralService.edit(`/trackers/balance`, {
          amount: this.newValueCustomModal
        })
          .then((data) => {
            this.$modal.hide('addBalance')
            this.$modal.show('successOfAddBalance')
            this.loadData()
          })
          .catch((e) => {
            this.$modal.hide('addBalance')
            this.$modal.show('errorOfAddBalance')
            this.$toast.error(e.response.data.message)
          })
      }
    },

    toggleTrackerHandler() {
      GeneralService.edit(`/trackers/statuses/update`, {
        trackersIds: this.activeSelectRowsMultiple,
        active: this.activateTab == this.dataLang['tracker_text13'] ? 0 : 1
      })
        .then((data) => {
          this.$modal.hide('acceptToggleTracker')
          if (this.activateTab == this.dataLang['tracker_text13']) {
            this.$modal.show('successOfDisabledTracker')
          } else {
            this.$modal.show('successOfActivateTracker')
          }

          this.loadData()
        })
        .catch((e) => {
          this.activeSelectRowsMultiple = []
          if (e.response.data.errorCode == 10) {
            this.dataOfErrors = e.response.data.data
            this.$modal.show('errorOfActivateTracker')
          }

          this.$modal.hide('acceptToggleTracker')
        })
    },

    resetFilter() {
      this.$refs.mainTable.$el
        .querySelectorAll('input.base-input--new')
        .forEach((elem, index) => {
          elem.value = ''
        })
      this.$refs.topFilter
        .querySelectorAll('input.base-input--new')
        .forEach((elem, index) => {
          elem.value = ''
        })
      this.filter = []
      this.selectsFilter.trackers = []
      this.selectsFilter.statuses = []
      this.loadData()
    },

    onCloseModal() {
      this.currentId = null
      this.updatedValues = {}
      this.dataForModal = {}
      this.activeSelectRowsMultiple = []
      this.selectsFromModal.objectsVehicle = []
      this.selectsFromModal.typeVehicles = []
    },
    deleteActionAndModal() {
      if (this.activeSelectRowsMultiple.length) {
        this.$modal.show('acceptDelete')
      } else {
        this.$modal.show('withoutOptionsModal')
      }
    },
    pincodeModalHandler() {
      this.pincode = ''
      this.activeSelectRowsMultiple = []
    },
    deleteAcceptRow() {
      GeneralService.delete(`/trackers/` + this.activeSelectRowsMultiple)
        .then((data) => {
          this.loadData()
          this.$modal.hide('acceptDeleteModal')
          this.$modal.show('successDeleteTracker')
        })
        .catch((e) => {
          console.log(e)
          this.$modal.hide('acceptDeleteModal')
          this.$modal.show('errorDeleteTracker')
        })
    },
    updateDataAboutPage() {
      GeneralService.get(`/trackers/page/info`)
        .then((data) => {
          this.aboutPageInfo = data.data
          console.log('pageInfo', data)
        })
        .catch((e) => {
          console.log(e)
        })
    },
    loadData() {
      this.localLoading = true
      this.updateDataAboutPage()
      Promise.all([
        GeneralService.get(`/objects/types`)
          .then((data) => {
            this.selectsDataForModal.typeVehicles = data.data.data.rows
          })
          .catch((e) => {
            console.log(e)
          }),

        GeneralService.get(
          `/trackers/select/objects?where=[{"field":"vehicles__type_id", "value":"1"}]`
        )
          .then((data) => {
            this.objectsVehiclesNew = data.data.data.rows
            this.objectsVehiclesNew.unshift({
              vehicles__name: 'Не назначен',
              vehicles__id: null,
              vehicles__gosnomer: ''
            })
            console.log(this.objectsVehiclesNew)
          })
          .catch((e) => {
            console.log(e)
          })
        // /fines/table/main?fields=vehicle_fines_statuses__id,vehicle_fines_statuses__name&uniqueBy=vehicle_fines_statuses__id
      ]).then(() => {
        GeneralService.get(
          `/trackers/table/${this.computedCurrentTabForPath}`,
          {
            params: {
              ...this.paginationFinesStateParams,
              ...(this.filter.length && { where: JSON.stringify(this.filter) })
            }
          }
        )
          .then((data) => {
            console.log(data)
            this.dataTable = data.data.data.rows
            this.getFinesAll(data.data.data)
            this.localLoading = false
          })
          .catch((e) => {
            console.log(e)
            this.$toast.error('Неизвестная ошибка при загрузке таблицы')
          })
      })
    },
    fillFilter(data) {
      let newValueFilter =
        data.type == 'like' ? `;percent;${data.value};percent;` : data.value
      if (!this.filter.find((x) => x.field == data.name)) {
        this.filter.push({
          field: data.name,
          value: newValueFilter,
          ...(data.type == 'like' && { operator: 'like' }),
          ...(data.operator && { operator: data.operator }),
          ...(data.mod && { mod: data.mod })
        })
      } else {
        this.filter[this.filter.findIndex((x) => x.field == data.name)].value =
          newValueFilter
      }
    },

    prepareLabelsForDefaultSelects(data, keyDataFromFetch, toTranslate) {
      for (const key in data) {
        data[key].forEach((element) => {
          element['label'] = element[Object.keys(element).pop()]
          element['key'] = key
          element['value'] = Object.keys(element)[0]
          element['selectName'] = keyDataFromFetch
        })
      }
      return data
    },
    ...mapActions('finesPagination', ['getFinesAll'])
  },
  mounted() {},
  created() {
    this.loadData()
  },
  computed: {
    ...mapState('translationModule', ['dataLang']),
    ...mapState('userSettingModule', ['userSetting']),
    ...mapState('finesPagination', ['paginationFinesStateParams']),
    ...mapGetters('finesPagination', ['numPages']),

    computedCurrentTabForPath() {
      if (this.activateTab == this.dataLang['tracker_text13']) {
        return 'active'
      } else {
        return 'disabled'
      }
    },

    columns() {
      return [
        {
          field: '',
          key: 'a6',
          type: 'checkbox',
          title: '',
          width: '3%',
          align: 'center'
        },
        {
          field: 'gps__tracker_id',
          key: '1636',
          title: this.dataLang['tracker_text23'],
          align: 'center',
          width: '7%',
          ellipsis: {
            showTitle: false,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
              </div>
            )
          }
        },
        {
          field: 'gps_typeunits__name',
          key: '15373',
          title: this.dataLang['tracker_text24'],
          align: 'center',
          width: '10%',

          ellipsis: {
            showTitle: false,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
              </div>
            )
          }
        },
        {
          field: 'gps__imei',
          key: '1721',
          title: this.dataLang['tracker_text25'],
          align: 'center',
          width: '12%',
          ellipsis: {
            showTitle: false,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
              </div>
            )
          }
        },
        {
          field: 'gps__gelios_unit_id',
          key: '137246',
          title: this.dataLang['tracker_text26'],
          align: 'center',
          width: '6%',

          ellipsis: {
            showTitle: false,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
              </div>
            )
          }
        },
        {
          field: 'vehicle_types__code',
          key: '165315',
          title: this.dataLang['tracker_text27'],
          align: 'center',
          width: '8%',

          ellipsis: {
            showTitle: false,
            lineClamp: 1
          },

          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{this.dataLang[row.vehicle_types__code]}</span>
          }
        },
        {
          field: 'vehicles__name',
          key: '1631411',
          title: this.dataLang['tracker_text28'],
          align: 'center',
          width: '8%',

          ellipsis: {
            showTitle: false,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
              </div>
            )
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>
                {row.vehicles__name} {row.vehicles__gosnumber}
              </span>
            )
          }
        },
        {
          field: 'gps__sim_phone',
          key: '163315',
          title: this.dataLang['tracker_text33'],
          align: 'center',
          width: '8%',

          ellipsis: {
            showTitle: false,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
              </div>
            )
          }
        },
        {
          field: 'gps__sim_operator',
          key: '126341',
          title: this.dataLang['tracker_text43'],
          align: 'center',
          width: '8%',

          ellipsis: {
            showTitle: false,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
              </div>
            )
          }
        },
        {
          field: 'gps__canpr',
          key: '163161',
          title: this.dataLang['tracker_text29'],
          align: 'center',
          width: '8%',

          ellipsis: {
            showTitle: false,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
              </div>
            )
          }
        },
        {
          field: 'gps__odometr',
          key: '1631261',
          title: this.dataLang['tracker_text95'],
          align: 'center',
          width: '8%',

          ellipsis: {
            showTitle: false,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
              </div>
            )
          }
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.top-line-custom {
  position: absolute;
  display: flex;
  justify-content: space-between;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  color: white;
  width: 65%;
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import RegPage from '../views/RegPage.vue'
import AuthPage from '../views/AuthPage.vue'
import NotFindPage from '@/views/404Page.vue'
import GlobalSettingPage from '../views/GlobalSettingPage.vue'
import OrdersPage from '../views/OrdersPage.vue'
import BrandsAndCharacteristicsPage from '@/views/BrandsAndCharacteristicsPage.vue'
import OwnersPage from '@/views/OwnersPage.vue'
import CountriesCitiesndSites from '@/views/CountriesCitiesndSites.vue'
import TransportPage from '@/views/TransportPage.vue'
import PasswordsPage from '@/views/PasswordsPage.vue'
import PasswordsAdminPage from '@/views/PasswordsAdminPage.vue'
import BookPhonesPage from '@/views/BookPhonesPage.vue'
import BookPhonesAdminPage from '@/views/BookPhonesAdminPage.vue'
import PromocodesAdminPage from '@/views/PromocodesAdminPage.vue'
import PromocodesPage from '@/views/PromocodesPage.vue'
import TasksPage from '@/views/TasksPage.vue'
import AcademyPage from '@/views/AcademyPage.vue'
import OrgpolicyPage from '@/views/OrgpolicyPage.vue'
import FinesPage from '@/views/FinesPage.vue'
import TollRoadsPage from '@/views/TollRoadsPage.vue'
import DevelopmentPage from '@/views/DevelopmentPage.vue'
import PricePage from '@/views/PricePage.vue'
import ClientsPage from '@/views/ClientsPage.vue'
import ClientsArchivePage from '@/views/ClientsArchivePage.vue'
import ClientsBlackListPage from '@/views/ClientsBlackListPage.vue'
import FinreestrPage from '@/views/FinreestrPage.vue'
import ChessPage from '@/views/ChessPage.vue'
import ContractsPage from '@/views/ContractsPage.vue'
import SummaryPage from '@/views/SummaryPage.vue'
import EmployeesAdminPage from '@/views/EmployeesAdminPage.vue'
import IntegrationsPage from '@/views/IntegrationsPage.vue'
import AnaliticsPage from '@/views/AnaliticsPage.vue'
import OrgpolicyAdminPage from '@/views/OrgpolicyAdminPage.vue'
import BidsPage from '@/views/BidsPage.vue'
import BidsOsagoPage from '@/views/BidsOsagoPage.vue'
import BidsPartsPage from '@/views/BidsPartsPage.vue'
import BidsFinesPage from '@/views/BidsFinesPage.vue'
import CompaniesYandexrentPage from '@/views/CompaniesYandexrentPage.vue'
import IntegrationsSiriusPage from '@/views/IntegrationsSiriusPage.vue'
import FullSummaryPage from '@/views/FullSummaryPage.vue'
import ModerationObjectPage from '@/views/ModerationObjectPage.vue'
import PasswordRecoveryPage from '@/views/PasswordRecoveryPage.vue'
import ClientsDebtPage from '@/views/ClientsDebtPage.vue'
import AnketaSecurPage from '@/views/AnketaSecurPage.vue'
import Sb1Page from '@/views/Sb1Page.vue'
import Sb2Page from '@/views/Sb2Page.vue'
import CompanyEmployees from '@/views/CompanyEmployees.vue'
import ChecklistAdminPage from '@/views/ChecklistAdminPage.vue'
import ChecklistPage from '@/views/ChecklistPage.vue'
import NotWelcomePage from '@/views/NotWelcomePage.vue'
import BrandsAndCharacteristicsPageAdmin from '@/views/BrandsAndCharacteristicsPageAdmin.vue'
import TransportVehiclesPage from '@/views/TransportVehiclesPage.vue'
import TestingPage from '@/views/TestingPage.vue'
import AccessService from '@/api/AccessService'
import AdSourcePage from '@/views/AdSourcePage.vue'
import NoticesPage from '@/views/NoticesPage.vue'
import TranslationsAdminPage from '@/views/TranslationsAdminPage.vue'
import SuperAdminSetting from '@/views/SuperAdminSetting.vue'
import ArchiveOrdersPage from '@/views/ArchiveOrdersPage.vue'
import SuperAdminRegionsPage from '@/views/SuperAdminRegionsPage.vue'
import SSEEventSource from '@/sse-instance.js'
import AuthRegisterPage from '@/views/AuthRegisterPage.vue'
import TarrifsPage from '@/views/TarrifsPage.vue'
import TransportVehicleArchivePage from '@/views/TransportVehicleArchivePage.vue'
import SuperRequestsForPaymentPage from '@/views/SuperRequestsForPaymentPage.vue'
import DevelopmentPageSecond from '@/views/DevelopmentPageSecond.vue'
import SuperGpsPage from '@/views/SuperGpsPage.vue'
import AdminGpsPage from '@/views/AdminGpsPage.vue'
import GeneralService from '@/api/GeneralService'



// ArchiveTransportVehiclesPage

Vue.use(VueRouter)

const routes = [
  {
    path: '/reg',
    name: 'RegPage',
    component: AuthRegisterPage,
    meta: {
      name: 'reg'
    }
  },
  {
    path: '/auth',
    name: 'AuthPage',
    component: AuthRegisterPage,
    meta: {
      name: 'auth'
    }
  },
  {
    path: '/recovery-password',
    name: 'PasswordRecoveryPage',
    component: AuthRegisterPage,
    meta: {
      name: 'recovery'
    }
  },

  { path: '/sb/:key', component: AnketaSecurPage },
  {
    path: '/',
    name: 'Home',
    redirect: { path: 'orders' },
    component: Home,
    meta: {
      requiresAuth: true
    },
    //   {
    //     "menu__code": "name_menu_super_word",
    //     "menu__css_class": null,
    //     "menu__description": "Страны, регионы, города",
    //     "menu__href": "/super/countries_regions_cities",
    //     "menu__id": 55,
    //     "menu__is_active": 1,
    //     "menu__is_admin": 0,
    //     "menu__is_superadmin": 1,
    //     "menu__item": "Страны, регионы, города",
    //     "menu__number": "45",
    //     "menu__parent_id": 54,
    //     "menu__position": 6,
    //     "menu__youtube_link": null
    // }
    children: [
      { path: 'ads', component: AdSourcePage, name: 'name_menu_admin_ads' },
      {
        path: 'super/countries_regions_cities',
        component: SuperAdminRegionsPage,
        name: 'name_menu_super_word'
      },
      {
        path: 'super/gps',
        component: SuperGpsPage,
        name: 'name_menu_super_gps'
      },
      {
        path: 'super/settings',
        component: SuperAdminSetting,
        name: 'name_menu_super_settings'
      },
      {
        path: 'super/translations',
        component: TranslationsAdminPage,
        name: 'name_menu_translations'
      },
      {
        path: 'transport/archive',
        component: TransportVehicleArchivePage,
        name: 'name_menu_transport_archive'
      },
      {
        path: 'transport-vehicles-page',
        component: TransportVehiclesPage,
        name: 'name_menu_orders'
      },

      {
        path: 'admin-gps',
        component: AdminGpsPage,
        name: 'name_menu_admin_gps'
      },

      //
      {
        path: 'orders/archive',
        component: ArchiveOrdersPage,
        name: 'name_menu_orders_archive'
      },
      {
        path: 'orders',
        component: OrdersPage,
        name: 'name_menu_orders',
        meta: { closeConnection: true }
      },
      {
        path: 'global-settings',
        component: GlobalSettingPage,
        name: 'name_menu_admin_settings'
      },
      {
        path: 'marks',
        component: BrandsAndCharacteristicsPage,
        name: 'name_menu_admin_marks'
      },
      { path: 'owners', component: OwnersPage, name: 'name_menu_admin_owners' },
      {
        path: 'countries',
        component: CountriesCitiesndSites,
        name: 'name_menu_admin_places_deliveries'
      },
      {
        path: 'transport/driver',
        component: TransportPage,
        name: 'name_menu_vehicles_driver'
      },
      {
        path: 'transport/nodriver',
        component: () => import('@/views/TransportVehiclesPage.vue'),
        name: 'name_menu_vehicles_nodriver_car'
      },
      {
        path: 'book/passwords',
        component: PasswordsPage,
        name: 'name_menu_helpdesk_passwords'
      },
      {
        path: 'book/admin-passwords',
        component: PasswordsAdminPage,
        name: 'name_menu_admin_passwords'
      },
      {
        path: 'book/admin-contacts',
        component: BookPhonesAdminPage,
        name: 'name_menu_admin_phonebook'
      },
      {
        path: 'book/contacts',
        component: BookPhonesPage,
        name: 'name_menu_helpdesk_phonebook'
      },
      {
        path: 'book/orgpolicy',
        component: OrgpolicyPage,
        name: 'name_menu_helpdesk_politiks'
      },
      {
        path: 'book/admin-orgpolicy',
        component: OrgpolicyAdminPage,
        name: 'name_menu_admin_politiks'
      },
      {
        path: 'promocodes',
        component: PromocodesPage,
        name: 'name_menu_promocodes'
      },
      { path: 'tasks', component: TasksPage, name: 'name_menu_helpdesk_tasks' },
      {
        path: 'academy',
        component: AcademyPage,
        name: 'name_menu_helpdesk_aсademy'
      },
      { path: 'fines', component: FinesPage, name: 'name_menu_security_fines' },
      {
        path: 'toll-roads',
        component: TollRoadsPage,
        name: 'name_menu_security_toll_roads'
      },
      {
        path: 'super/requests_for_payment',
        component: SuperRequestsForPaymentPage,
        name: 'name_menu_super_fines'
      },

      {
        path: 'contracts',
        component: ContractsPage,
        name: 'name_menu_contracts'
      },
      { path: 'chess', component: ChessPage, name: 'name_menu_chess' },
      {
        path: 'clients/white',
        component: ClientsPage,
        name: 'name_menu_clients_white'
      },
      {
        path: 'clients/archive',
        component: ClientsArchivePage,
        name: 'name_menu_clients_archive'
      },
      {
        path: 'clients/black',
        component: ClientsBlackListPage,
        name: 'name_menu_security_blacklist'
      },
      {
        path: 'finreestr',
        component: FinreestrPage,
        name: 'name_menu_finance_registry'
      },
      {
        path: 'balance',
        component: DevelopmentPage,
        name: 'name_menu_finance_balance'
      },
      {
        path: 'company_employees',
        component: CompanyEmployees,
        name: 'name_menu_helpdesk_employees'
      },
      { path: 'sb-1', component: Sb1Page, name: 'name_menu_security_sb1' },
      { path: 'sb-2', component: Sb2Page, name: 'name_menu_security_sb2' },
      {
        path: 'gps',
        component: DevelopmentPage,
        name: 'name_menu_security_gps'
      },
      {
        path: 'dev2',
        component: DevelopmentPageSecond,
        name: 'name_menu_security_gps'
      },

      {
        path: 'events',
        component: DevelopmentPage,
        name: 'name_menu_security_events'
      },
      {
        path: 'checklist',
        component: ChecklistPage,
        name: 'name_menu_helpdesk_checklist'
      },
      {
        path: 'schedule',
        component: DevelopmentPage,
        name: 'name_menu_helpdesk_schedule'
      },
      {
        path: 'sms',
        component: DevelopmentPage,
        name: 'name_menu_helpdesk_sms'
      },
      {
        path: 'summary',
        component: SummaryPage,
        name: 'name_menu_admin_summary'
      },
      {
        path: 'stat',
        component: FullSummaryPage,
        name: 'name_menu_admin_stat'
      },
      {
        path: 'employees',
        component: EmployeesAdminPage,
        name: 'name_menu_admin_employees'
      },
      {
        path: 'admin-checklist',
        component: ChecklistAdminPage,
        name: 'name_menu_admin_checklist'
      },
      {
        path: 'admin-schedule',
        component: DevelopmentPage,
        name: 'name_menu_admin_schedule'
      },
      {
        path: 'admin-promocodes',
        component: PromocodesAdminPage,
        name: 'name_menu_admin_promocodes'
      },
      {
        path: 'marks-admin',
        component: BrandsAndCharacteristicsPageAdmin,
        name: 'name_menu_super_marks'
      },
      { path: 'price', component: TarrifsPage, name: 'name_menu_admin_price' },
      {
        path: 'bases',
        component: DevelopmentPage,
        name: 'name_menu_security_bases'
      },
      {
        path: 'loading',
        component: AnaliticsPage,
        name: 'name_menu_admin_loading'
      },
      {
        path: 'integrations',
        component: IntegrationsPage,
        name: 'name_menu_admin_integrations'
      },
      { path: 'bids', component: BidsPage, name: 'name_menu_admin_bid' },
      {
        path: 'super/companies',
        component: CompaniesYandexrentPage,
        name: 'name_menu_super_companies'
      },
      {
        path: 'super/integrators',
        component: IntegrationsSiriusPage,
        name: 'name_menu_super_integrators'
      },
      {
        path: 'super/bid-osago',
        component: BidsOsagoPage,
        name: 'name_menu_super_bid_osago'
      },
      {
        path: 'super/bid-parts',
        component: BidsPartsPage,
        name: 'name_menu_super_bid_parts'
      },
      {
        path: '/super/moderation-page',
        component: ModerationObjectPage,
        name: 'name_menu_super_moderation_ts'
      },
      {
        path: 'super/bid-fines',
        component: BidsFinesPage,
        name: 'name_menu_super_bid_fines'
      },
      {
        path: 'account/settings',
        component: BidsFinesPage,
        name: 'name_menu_super_bid_fines'
      },
      {
        path: 'development',
        component: DevelopmentPage,
        name: 'name_menu_development'
      },
      {
        path: 'clients/debt',
        component: ClientsDebtPage,
        name: 'name_menu_development'
      },
      {
        path: 'company_notifications',
        component: NoticesPage,
        name: 'name_menu_notifications'
      },
      { path: 'testing', component: TestingPage, name: 'testing' },
      { path: 'testing', component: TestingPage, name: 'testing' },
      { path: '*', component: NotFindPage }
    ]
  }
  // { path: 'not-welcome', component: NotWelcomePage, name:"name_menu_super_bid_fines"},
  /*
  {
    path: '/about',PricePage
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AboutView.vue')
  }
  ,
  {
    path: '/menu/*',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AboutView.vue')
  } */
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const outUrls = ['/gelios/lk']

router.beforeEach(async (to, from, next) => {
  if (
    (to.name == 'AuthPage' ||
      to.name == 'RegPage' ||
      to.name == 'PasswordRecoveryPage') &&
    localStorage.getItem('user-token')
  ) {
    router.push('/')
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (outUrls.find((outCurrentUrls) => outCurrentUrls == to.path)) {
      let answerAboutUrl = await GeneralService.get('/gelios/lk')
      window.open(answerAboutUrl.data.data.lk, '_blank')
    } else {
      AccessService.tokenVerification({ params: { page: `${to.path}` } })
        .then((data) => {
          console.log(data)
          next()
        })
        .catch((err) => {
          localStorage.removeItem('user-token')
          localStorage.removeItem('translations')
          router.push('/auth')
        })
    }

  } else {
    next()
  }
})

router.beforeResolve((to, from, next) => {
  // console.log(to, from);
  // if (from.matched.some((record) => record.meta.closeConnection)) {
  //   console.log(2222222222222222222222222222222222222222)
  //   SSEEventSource.close()
  // }
  next()
})

export default router

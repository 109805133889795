<template>
  <div>
    <BaseTopLine></BaseTopLine>
    <div class="px-20 pt-10">
      <!-- <div class="base-button px-20 rounded-10" @click="actionForEditTable">
            {{ dataLang['registry_ads_text2'] }}
          </div> -->
    </div>
    <div class="p-20">
      <vue-tabs v-if="!localLoading">
        <v-tab title="Договоры">
          <div class="py-20">
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.crm_setting_1
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.crm_setting_1
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.crm_setting_1
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.crm_setting_2
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.crm_setting_2
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.crm_setting_2
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.crm_setting_4
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.crm_setting_4
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.crm_setting_4
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.crm_setting_22
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.crm_setting_22
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.crm_setting_22
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.crm_setting_5
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.crm_setting_5
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.crm_setting_5
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.crm_setting_6
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.crm_setting_6
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.crm_setting_6
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.crm_setting_7
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.crm_setting_7
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.crm_setting_7
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div></div
        ></v-tab>
        <v-tab title="Доступы">
          <div class="py-20">
            <span>smssimple</span>
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.sms_simple_login
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.sms_simple_login
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.sms_simple_login
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.sms_simple_password
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.sms_simple_password
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.sms_simple_password
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.sms_simple_id
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.sms_simple_id
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.sms_simple_id
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>
          </div>
        </v-tab>
        <v-tab title="Платные услуги">
          <div class="py-20">
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.sms_cost.company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.sms_cost.company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.sms_cost.company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.crm_setting_47
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.crm_setting_47
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.crm_setting_47
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.fine_cost_month
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.fine_cost_month
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.fine_cost_month
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.crm_setting_33
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.crm_setting_33
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.crm_setting_33
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.crm_setting_34
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.crm_setting_34
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.crm_setting_34
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.crm_setting_35
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.crm_setting_35
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.crm_setting_35
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.crm_setting_36
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.crm_setting_36
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.crm_setting_36
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.crm_setting_37
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.crm_setting_37
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.crm_setting_37
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.crm_setting_38
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.crm_setting_38
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.crm_setting_38
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.crm_setting_39
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.crm_setting_39
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.crm_setting_39
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.crm_setting_40
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.crm_setting_40
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.crm_setting_40
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.crm_setting_41
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.crm_setting_41
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.crm_setting_41
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.crm_setting_42
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.crm_setting_42
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.crm_setting_42
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.crm_setting_43
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.crm_setting_43
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.crm_setting_43
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>

            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.crm_setting_44
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.crm_setting_44
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.crm_setting_44
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>

          </div>
        </v-tab>
        <v-tab title="Прочее">
          <div class="py-20">
            <span>Разработка</span>
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.crm_setting_20
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.crm_setting_20
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.crm_setting_20
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>
          </div>
          <div class="py-20">
            <span>Настройки таймеров</span>
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.timer_order_status_new
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.timer_order_status_new
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.timer_order_status_new
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.timer_order_status_consulation
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.timer_order_status_consulation
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.timer_order_status_consulation
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.timer_order_status_confirmed
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.timer_order_status_confirmed
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.timer_order_status_confirmed
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.timer_order_status_security
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.timer_order_status_security
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.timer_order_status_security
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.timer_order_status_contract
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.timer_order_status_contract
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.timer_order_status_contract
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.timer_order_status_fill_anket
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.timer_order_status_fill_anket
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.timer_order_status_fill_anket
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.timer_order_status_payment
                    .company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.timer_order_status_payment
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.timer_order_status_payment
                    .company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>
            <span>Длина смс</span>
            <div class="wp-30">
              <BaseInputNew
                placeholder=""
                :width="'225'"
                class="mb-10"
                :input-name="
                  dataFromSetting.sms_length.company_settings_super__setting_key
                "
                @input="updateValue"
                :value="
                  dataFromSetting.sms_length
                    .company_settings_super__setting_value
                "
                :name-sign="
                  dataFromSetting.sms_length.company_settings_super__description
                "
              >
              </BaseInputNew>
            </div>
          </div>
        </v-tab>
      </vue-tabs>
      <!-- <div
        class="wp-30"
        v-for="(item, index) of dataFromSetting"
        :key="item.company_settings_super__id"
      >
        <BaseInputNew
          placeholder=""
          :width="'225'"
          class="mb-10"
          :input-name="item.company_settings_super__setting_key"
          @input="updateValue"
          :value="item.company_settings_super__setting_value"
          :name-sign="item.company_settings_super__description"
        >
        </BaseInputNew>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import GeneralService from '@/api/GeneralService.js'
import { VueTabs, VTab } from 'vue-nav-tabs/dist/vue-tabs.js'
export default {
  components: { VueTabs, VTab },

  props: {},
  data() {
    return {
      localLoading: true,
      dataFromSetting: {},
      updatedValues: {}
    }
  },
  methods: {
    updateValue(data) {
      console.log(data)
      this.updatedValues[data.name] = data.value
      console.log(this.updatedValues)
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.requestToPath, 2000)
    },
    requestToPath() {
      GeneralService.edit('/super/settings', this.updatedValues)
        .then((data) => {
          this.$toast.success(data.data.message)
          //   console.log(data.data.languages.rows)
        })
        .catch((err) => {
          this.$toast.success(err.response.data.message)
          console.log(err)
        })
      this.updatedValues = {}
    }
  },
  mounted() {},

  created() {
    // ?where=[{"field":"request_services__type_service_id","value":8}]
    Promise.all([
      GeneralService.get('/super/settings')
        .then((data) => {
          data.data.data.rows.forEach((element) => {
            console.log(element)
            // company_settings_super__description
            this.dataFromSetting[element.company_settings_super__setting_key] =
              { ...element }
          })
          this.localLoading = false
          console.log(this.dataFromSetting)
          // this.dataFromSetting = data.data.data.rows
          // //   console.log(data.data.languages.rows)
        })
        .catch((err) => {
          console.log(err)
        })
    ]).then(() => {
      console.log(123)
    })
  },

  computed: {
    ...mapState('userSettingModule', ['userSetting']),
    ...mapState('translationModule', ['dataLang'])

    // ...mapState('bidsOsagoModule', [
    //   'bidsOsagoData',
    //   'bidsOsagoLoadStatus',
    //   'paginationbidsOsagoStateParams',
    // ]),
    // ...mapState('translationModule', ['dataLang']),
    // ...mapGetters('bidsOsagoModule', ['numPages']),
  }
}
</script>

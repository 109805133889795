var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('BaseTopLine'),_c('div',{staticClass:"px-20 pt-10"}),_c('div',{staticClass:"p-20"},[(!_vm.localLoading)?_c('vue-tabs',[_c('v-tab',{attrs:{"title":"Договоры"}},[_c('div',{staticClass:"py-20"},[_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.crm_setting_1
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.crm_setting_1
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.crm_setting_1
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.crm_setting_2
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.crm_setting_2
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.crm_setting_2
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.crm_setting_4
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.crm_setting_4
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.crm_setting_4
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.crm_setting_22
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.crm_setting_22
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.crm_setting_22
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.crm_setting_5
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.crm_setting_5
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.crm_setting_5
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.crm_setting_6
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.crm_setting_6
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.crm_setting_6
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.crm_setting_7
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.crm_setting_7
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.crm_setting_7
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1)])]),_c('v-tab',{attrs:{"title":"Доступы"}},[_c('div',{staticClass:"py-20"},[_c('span',[_vm._v("smssimple")]),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.sms_simple_login
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.sms_simple_login
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.sms_simple_login
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.sms_simple_password
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.sms_simple_password
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.sms_simple_password
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.sms_simple_id
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.sms_simple_id
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.sms_simple_id
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1)])]),_c('v-tab',{attrs:{"title":"Платные услуги"}},[_c('div',{staticClass:"py-20"},[_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.sms_cost.company_settings_super__setting_key,"value":_vm.dataFromSetting.sms_cost.company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.sms_cost.company_settings_super__description},on:{"input":_vm.updateValue}})],1),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.crm_setting_47
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.crm_setting_47
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.crm_setting_47
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.fine_cost_month
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.fine_cost_month
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.fine_cost_month
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.crm_setting_33
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.crm_setting_33
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.crm_setting_33
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.crm_setting_34
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.crm_setting_34
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.crm_setting_34
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.crm_setting_35
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.crm_setting_35
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.crm_setting_35
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.crm_setting_36
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.crm_setting_36
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.crm_setting_36
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.crm_setting_37
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.crm_setting_37
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.crm_setting_37
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.crm_setting_38
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.crm_setting_38
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.crm_setting_38
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.crm_setting_39
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.crm_setting_39
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.crm_setting_39
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.crm_setting_40
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.crm_setting_40
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.crm_setting_40
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.crm_setting_41
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.crm_setting_41
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.crm_setting_41
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.crm_setting_42
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.crm_setting_42
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.crm_setting_42
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.crm_setting_43
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.crm_setting_43
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.crm_setting_43
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.crm_setting_44
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.crm_setting_44
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.crm_setting_44
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1)])]),_c('v-tab',{attrs:{"title":"Прочее"}},[_c('div',{staticClass:"py-20"},[_c('span',[_vm._v("Разработка")]),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.crm_setting_20
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.crm_setting_20
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.crm_setting_20
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1)]),_c('div',{staticClass:"py-20"},[_c('span',[_vm._v("Настройки таймеров")]),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.timer_order_status_new
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.timer_order_status_new
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.timer_order_status_new
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.timer_order_status_consulation
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.timer_order_status_consulation
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.timer_order_status_consulation
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.timer_order_status_confirmed
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.timer_order_status_confirmed
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.timer_order_status_confirmed
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.timer_order_status_security
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.timer_order_status_security
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.timer_order_status_security
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.timer_order_status_contract
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.timer_order_status_contract
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.timer_order_status_contract
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.timer_order_status_fill_anket
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.timer_order_status_fill_anket
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.timer_order_status_fill_anket
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.timer_order_status_payment
                  .company_settings_super__setting_key,"value":_vm.dataFromSetting.timer_order_status_payment
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.timer_order_status_payment
                  .company_settings_super__description},on:{"input":_vm.updateValue}})],1),_c('span',[_vm._v("Длина смс")]),_c('div',{staticClass:"wp-30"},[_c('BaseInputNew',{staticClass:"mb-10",attrs:{"placeholder":"","width":'225',"input-name":_vm.dataFromSetting.sms_length.company_settings_super__setting_key,"value":_vm.dataFromSetting.sms_length
                  .company_settings_super__setting_value,"name-sign":_vm.dataFromSetting.sms_length.company_settings_super__description},on:{"input":_vm.updateValue}})],1)])])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import TranslationService from "@/api/TranslationService.js";
export const translationModule = {
  namespaced: true,
  state: () => ({
    dataLang: null,
    loadStatus: ''
  }),

  getters: {},
  mutations: {
    get_all(state, data) {
      console.log(data)
      state.dataLang = data;
    },
    load_request(state, status) {
      state.loadStatus = 'request'
    },
    load_success(state) {
      state.loadStatus = 'success'
    },
    load_error(state) {
      state.loadStatus = 'error'
    },
  },
  actions: {
    getTranslation({ commit }, langID) {
      commit("load_request");
      let localTranslation = localStorage.getItem('translations');

      if(localTranslation){
        commit("get_all", JSON.parse(localTranslation));
        commit("load_success");
      }else{
        TranslationService.getTranslate(langID, {params: {limit:5000}})
          .then((data) => {
            console.log(data);
            localStorage.setItem('translations', JSON.stringify(data.data.data.rows))
            commit("get_all", data.data.data.rows);
            commit("load_success");
          })
          .catch((error) => {
            console.log(error);
            commit("load_request");
            commit("load_error");
          });

      }
    },
  },
};
